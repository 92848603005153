<template>
	<div>
		<CustomersTable
			:route="route"
			:stateVariable="stateVariable"
		/>
		<CustomersForm
			:route="route"
			:stateVariable="stateVariable"
		/>
	</div>
</template>

<script>
	import CustomersTable from "./CustomersTable.vue";
	import CustomersForm from "./CustomersForm.vue";

	export default {
		components: {
			CustomersTable,
			CustomersForm,
		},
		data() {
			return {
				route: "customers",
				stateVariable: "customers",
			};
		},
	};
</script>
