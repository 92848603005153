<template>
	<TableDefault
		:route="route"
		:stateVariable="stateVariable"
		:list="$store.state[stateVariable].list"
		:total="$store.state[stateVariable].listTotal"
		:filters="filters"
		:delete="'users'"
		:showSelectMultipleColumn="false"
		:showDeleteButton="false"
	>
		<template #columns>
			<Column header="Nombre" field="name"></Column>
			<Column header="Email" field="email"></Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";
	import { mapState } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "name",
						value: null,
						type: "string",
						placeholder: "Nombre",
					},
					{
						name: "email",
						value: null,
						type: "string",
						placeholder: "Email",
					},
				],
			};
		},
		computed: {
			...mapState(["users"]),
		},
	};
</script>
