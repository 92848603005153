<template>
	<div>
		<Loading />
		<form @keydown="$event.key === 'Enter' ? handleLogin() : null">
			<div class="row mx-auto">
				<div class="col-12">
					<InputText
						label="Email"
						:error="form.errors.get('email')"
						@change-value="(value) => (form.email = value)"
					/>
				</div>
				<div class="col-12 mt-4">
					<InputPassword
						label="Contraseña"
						:error="form.errors.get('password')"
						:feedback="false"
						@change-value="(value) => (form.password = value)"
					/>
				</div>
				<div class="col-12 d-flex justify-content-end mt-4">
					<Button @click="handleLogin()" label="Iniciar sesión" />
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import Form from "vform";
	import Loading from "@/components/partials/Loading.vue";
	import { mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Loading,
		},
		data: () => ({
			form: new Form({
				email: null,
				password: null,
			}),
		}),
		methods: {
			...mapActions(["login"]),
			...mapMutations(["toggleLoading"]),
			handleLogin() {
				this.toggleLoading(true);

				this.login({
					form: this.form,
					errors: this.errors,
				})
					.then(() => {
						this.toggleLoading(false);
					})
					.catch(() => {
						this.toggleLoading(false);
					});
			},
		},
	};
</script>
