<template>
	<Dialog
		v-model:visible="$store.state[stateVariable].dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '50vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form>
			<div class="row gy-3">
				<div v-if="$store.state[stateVariable].register" class="col-12">
					<div class="d-flex flex-wrap gap-2">
						<Button
							class="border-primary bg-primary"
							icon="pi pi-print"
							label="Imprimir"
							@click="
								printOperationOrder({
									id: $store.state[stateVariable].register.id,
									mode: 'intranet',
								})
							"
						/>
						<Button
							class="border-primary bg-primary"
							icon="pi pi-file"
							label="Imprimir resguardo para el cliente"
							@click="
								printOperationOrder({
									id: $store.state[stateVariable].register.id,
									mode: 'public',
								})
							"
						/>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<InputText
						ref="number"
						label="Nº OT"
						:disabled="true"
						:error="form.errors.get('number')"
						@change-value="(value) => (form.number = value)"
					/>
				</div>
				<div class="col-12 col-md-6">
					<DropDown
						ref="status"
						label="Estado"
						:options="statusOptions"
						optionLabel="name"
						optionValue="name"
						:displayText="'name'"
						:disabled="disabled"
						:error="form.errors.get('status')"
						@change-value="(value) => (form.status = value)"
					/>
				</div>
				<div class="col-12 col-md-6">
					<InputText
						ref="opening"
						label="Apertura"
						:disabled="disabled"
						:error="form.errors.get('opening')"
						@change-value="(value) => (form.opening = value)"
					/>
				</div>
				<div class="col-12 col-md-6">
					<InputText
						ref="finished"
						label="Finalizada"
						:disabled="disabled"
						:error="form.errors.get('finished')"
						@change-value="(value) => (form.finished = value)"
					/>
				</div>
				<div class="col-12 col-md-6">
					<InputText
						ref="total_hours"
						label="Nº horas"
						:disabled="disabled"
						:error="form.errors.get('total_hours')"
						@change-value="(value) => (form.total_hours = value)"
					/>
				</div>
				<div class="col-12 col-md-6">
					<InputText
						ref="price"
						label="Entrega a cuenta"
						:disabled="disabled"
						:error="form.errors.get('price')"
						@change-value="(value) => (form.price = value)"
					/>
				</div>
				<div class="col-12 col-md-6">
					<DropDown
						ref="customer_id"
						label="Cliente"
						:options="customers.list"
						optionLabel="name"
						:displayText="'name'"
						:disabled="disabled || selectedCustomer != null"
						:error="form.errors.get('customer_id')"
						@change-value="(value) => (form.customer_id = value)"
					/>
				</div>
				<div class="col-12 col-md-6">
					<DropDown
						ref="user_id"
						label="Asignado a"
						:options="users.list"
						optionLabel="name"
						:displayText="'name'"
						:disabled="disabled"
						:error="form.errors.get('user_id')"
						@change-value="(value) => (form.user_id = value)"
					/>
				</div>
				<div class="col-12 col-md-6">
					<InputSwitch
						ref="is_finished"
						label="OT Finalizada"
						:disabled="disabled"
						:error="form.errors.get('is_finished')"
						@change-value="(value) => (form.is_finished = value)"
					/>
				</div>
				<div class="col-12">
					<TextArea
						ref="description"
						label="Descripción"
						:disabled="disabled"
						:error="form.errors.get('description')"
						:rows="5"
						@change-value="(value) => (form.description = value)"
					/>
				</div>
				<div class="col-12">
					<TextArea
						ref="observations"
						label="Observaciones"
						:disabled="disabled"
						:error="form.errors.get('observations')"
						:rows="5"
						@change-value="(value) => (form.observations = value)"
					/>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-end">
				<Button @click="save()" label="Guardar" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
			selectedCustomer: {
				type: Object,
				required: false,
			},
			fromAlerts: {
				type: Boolean,
				required: false,
			},
			onGoing: {
				type: Boolean,
				required: false,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "orden de trabajo",
			title: `Añadir orden de trabajo`,
			disabled: false,
			tags: [],
		}),
		methods: {
			...mapActions([
				"sendForm",
				"getRegisters",
				"getLastNumber",
				"printOperationOrder",
			]),
			...mapMutations([
				"toggleFormDialog",
				"changeCurrentRegister",
				"toggleOperationOrderSaved",
			]),
			save() {
				const stateVariable = this.$store.state[this.stateVariable];

				const update = stateVariable.register != null;
				const url = `/operation-orders${
					update ? `/${stateVariable.register.id}` : ""
				}`;

				this.sendForm({
					method: update ? "put" : "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});

						if (!this.fromAlerts) {
							this.getRegisters({
								route: this.route,
								stateVariable: this.stateVariable,
								page: stateVariable.currentPage,
								rows: stateVariable.rows,
								additionalParams: {
									selectedCustomerId:
										this.selectedCustomer?.id,
									fromAlerts: this.fromAlerts,
									onGoing: this.onGoing,
								},
							});
						} else {
							this.toggleOperationOrderSaved(true);

							this.$nextTick(() => {
								this.toggleOperationOrderSaved(false);
							});
						}
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.disabled = false;

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
			},
			show() {
				this.clearForm();

				this.getRegisters({
					route: "/customers",
					stateVariable: "customers",
					getAll: true,
					showLoading: false,
				}).then(() => {
					if (this.selectedCustomer != null) {
						this.$refs["customer_id"].model =
							this.selectedCustomer.id;
					}
				});

				this.getRegisters({
					route: "/users",
					stateVariable: "users",
					getAll: true,
					showLoading: false,
				});

				const stateVariable = this.$store.state[this.stateVariable];
				const register = stateVariable.register;

				if (register != null) {
					for (const key in register) {
						if (
							Object.hasOwnProperty.call(register, key) &&
							this.$refs[key] != undefined
						) {
							this.$refs[key].model = register[key];
						}
					}

					if (stateVariable.dialogMode == "edit") {
						this.title = `Editar ${this.modelName}`;
						this.disabled = false;
					} else {
						this.title = `Ver ${this.modelName}`;
						this.disabled = true;
					}
				} else {
					this.getLastNumber().then((response) => {
						this.$refs["number"].model = response.data.number;
					});
				}
			},
		},
		computed: {
			...mapState([
				"dialogDefaults",
				"operationOrders",
				"statusOptions",
				"customers",
				"users",
			]),
		},
	};
</script>
