<template>
	<div>
		<OperationOrdersTable
			ref="operationOrdersTable"
			:route="route"
			:stateVariable="stateVariable"
			:selectedCustomer="selectedCustomer"
			:fromAlerts="fromAlerts"
			:onGoing="onGoing"
		/>
		<OperationOrdersForm
			:route="route"
			:stateVariable="stateVariable"
			:selectedCustomer="selectedCustomer"
			:fromAlerts="fromAlerts"
			:onGoing="onGoing"
			@saved="handleSaved()"
		/>
	</div>
</template>

<script>
	import OperationOrdersTable from "./OperationOrdersTable.vue";
	import OperationOrdersForm from "./OperationOrdersForm.vue";

	export default {
		components: {
			OperationOrdersTable,
			OperationOrdersForm,
		},
		props: {
			selectedCustomer: {
				type: Object,
				required: false,
			},
			fromAlerts: {
				type: Boolean,
				required: false,
			},
			onGoing: {
				type: Boolean,
				required: false,
			},
		},
		data() {
			return {
				route: "operation-orders",
			};
		},
		computed: {
			stateVariable() {
				if (this.fromAlerts) {
					if (this.onGoing) {
						return "onGoinAlerts";
					} else {
						return "alerts";
					}
				} else {
					return "operationOrders";
				}
			},
		},
		methods: {
			handleSaved() {
				this.$refs.operationOrdersTable.handleGetRegisters();
			},
		},
	};
</script>
