<template>
	<div>
		<div class="row gy-4">
			<div class="col-12 col-md-6">
				<Welcome />
			</div>
			<!-- <div class="col-12 col-md-6 col-lg-3">
				<TotalRegisters :data="stats" />
			</div> -->
			<!-- <div class="col-12 col-md-6">
				<MaxCategories :data="stats" />
			</div> -->
		</div>
	</div>
</template>

<script>
	import Welcome from "@/components/dashboard/Welcome.vue";
	// import TotalRegisters from "@/components/dashboard/TotalRegisters.vue";
	// import MaxCategories from "@/components/dashboard/MaxCategories.vue";

	// import { mapActions, mapState } from "vuex";

	export default {
		components: {
			// TotalRegisters,
			// MaxCategories,
			Welcome,
		},
		// data() {
		// 	return {
		// 		stats: null,
		// 	};
		// },
		// methods: {
		// 	...mapActions(["getStats"]),
		// },
		// mounted() {
		// 	this.getStats().then((response) => {
		// 		this.stats = response.data;
		// 	});
		// },
	};
</script>
