<template>
	<Dialog
		v-model:visible="$store.state[stateVariable].dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '50vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3">
				<div class="col-12 col-md-8">
					<InputText
						ref="name"
						label="Nombre / Razón social"
						:disabled="disabled"
						:error="form.errors.get('name')"
						@change-value="(value) => (form.name = value)"
					/>
				</div>
				<div class="col-12 col-md-4">
					<InputText
						ref="nif"
						label="NIF / CIF"
						:disabled="disabled"
						:error="form.errors.get('nif')"
						@change-value="(value) => (form.nif = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="address"
						label="Dirección"
						:disabled="disabled"
						:error="form.errors.get('address')"
						@change-value="(value) => (form.address = value)"
					/>
				</div>
				<div class="col-12 col-md-8">
					<InputText
						ref="poblation"
						label="Población"
						:disabled="disabled"
						:error="form.errors.get('poblation')"
						@change-value="(value) => (form.poblation = value)"
					/>
				</div>
				<div class="col-12 col-md-4">
					<InputText
						ref="postal_code"
						label="Código postal"
						:disabled="disabled"
						:error="form.errors.get('postal_code')"
						@change-value="(value) => (form.postal_code = value)"
					/>
				</div>
				<div class="col-12 col-md-6">
					<InputText
						ref="phone_1"
						label="Teléfono 1"
						:disabled="disabled"
						:error="form.errors.get('phone_1')"
						@change-value="(value) => (form.phone_1 = value)"
					/>
				</div>
				<div class="col-12 col-md-6">
					<InputText
						ref="phone_2"
						label="Teléfono 2"
						:disabled="disabled"
						:error="form.errors.get('phone_2')"
						@change-value="(value) => (form.phone_2 = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="email"
						label="Email"
						:disabled="disabled"
						:error="form.errors.get('email')"
						@change-value="(value) => (form.email = value)"
					/>
				</div>
				<div class="col-12">
					<TextArea
						ref="observations"
						label="Observaciones"
						:disabled="disabled"
						:error="form.errors.get('observations')"
						@change-value="(value) => (form.observations = value)"
					/>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-end">
				<Button @click="save()" label="Guardar" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "cliente",
			title: `Añadir cliente`,
			disabled: false,
			tags: [],
		}),
		methods: {
			...mapActions(["sendForm", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const update = this.customers.register != null;
				const url = `/customers${
					update ? `/${this.customers.register.id}` : ""
				}`;

				this.sendForm({
					method: update ? "put" : "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});

						this.getRegisters({
							route: this.route,
							stateVariable: this.stateVariable,
							page: this.customers.currentPage,
							rows: this.customers.rows,
						});
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.disabled = false;

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
			},
			show() {
				this.clearForm();

				const register = this.customers.register;

				if (register != null) {
					for (const key in register) {
						if (
							Object.hasOwnProperty.call(register, key) &&
							this.$refs[key] != undefined
						) {
							this.$refs[key].model = register[key];
						}
					}

					if (this.customers.dialogMode == "edit") {
						this.title = `Editar ${this.modelName}`;
						this.disabled = false;
					} else {
						this.title = `Ver ${this.modelName}`;
						this.disabled = true;
					}
				}
			},
		},
		computed: {
			...mapState(["dialogDefaults", "customers"]),
		},
	};
</script>
