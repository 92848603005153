<template>
	<TableDefault
		ref="tableDefault"
		:route="route"
		:stateVariable="stateVariable"
		:list="$store.state[stateVariable].list"
		:total="$store.state[stateVariable].listTotal"
		:filters="filters"
		:delete="'customers'"
		:showActionsColumn="false"
		:showSelectMultipleColumn="false"
	>
		<template #columns>
			<Column header="Nombre" field="name"></Column>
			<Column header="NIF / CIF" field="nif">
				<template #body="slotProps">
					<NullableColumn :data="slotProps.data.nif" />
				</template>
			</Column>
			<Column header="Teléfono 1">
				<template #body="slotProps">
					<NullableColumn :data="slotProps.data.phone_1" />
				</template>
			</Column>
			<Column header="Teléfono 2">
				<template #body="slotProps">
					<NullableColumn :data="slotProps.data.phone_2" />
				</template>
			</Column>
			<Column header="Email">
				<template #body="slotProps">
					<NullableColumn :data="slotProps.data.email" />
				</template>
			</Column>
			<Column header="Acciones">
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2">
						<Button
							class="border-primary bg-primary"
							icon="pi pi-book"
							v-tooltip.top="'Órdenes de trabajo'"
							@click="handleShowOperationOrders(slotProps.data)"
						/>
						<Button
							class="border-primary bg-primary"
							icon="pi pi-pencil"
							@click="handleShowRegister(slotProps.data, 'edit')"
						/>
						<!-- <Button
							class="border-primary bg-primary"
							icon="pi pi-trash"
							@click="handleDeleteRegister(slotProps.data.id)"
						/> -->
					</div>
				</template>
			</Column>
		</template>
	</TableDefault>

	<OperationOrders
		v-if="showOperationOrders"
		:selectedCustomer="selectedCustomer"
		class="mt-4"
	/>
</template>

<script>
	import Column from "primevue/column";
	import { mapState, mapMutations } from "vuex";
	import OperationOrders from "@/components/operation-orders/OperationOrders.vue";

	export default {
		components: {
			Column,
			OperationOrders,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "name",
						value: null,
						type: "string",
						placeholder: "Nombre",
					},
					{
						name: "nif",
						value: null,
						type: "string",
						placeholder: "NIF / CIF",
					},
					{
						name: "phone_1",
						value: null,
						type: "string",
						placeholder: "Teléfono 1",
					},
					{
						name: "phone_2",
						value: null,
						type: "string",
						placeholder: "Teléfono 2",
					},
					{
						name: "email",
						value: null,
						type: "string",
						placeholder: "Email",
					},
				],
				showOperationOrders: false,
				selectedCustomer: null,
			};
		},
		computed: {
			...mapState(["customers"]),
		},
		methods: {
			...mapMutations(["changeSelectedCustomer"]),
			handleShowRegister(data, mode) {
				this.$refs.tableDefault.showRegister(data, mode);
			},
			handleDeleteRegister(id) {
				this.$refs.tableDefault.deleteRegister(id);
			},
			handleShowOperationOrders(customer) {
				this.selectedCustomer = customer;
				this.showOperationOrders = false;

				this.$nextTick(() => {
					this.changeSelectedCustomer(customer.id);
					this.showOperationOrders = true;
				});
			},
		},
	};
</script>
