<template>
	<div class="w-100 d-flex flex-column">
		<template v-for="(item, index) in items" :key="index">
			<Link class="px-3" :item="item" @click="toggleSidebar(false)" />
		</template>
	</div>
</template>

<script>
	import Link from "./Link.vue";
	import { mapMutations } from "vuex";

	export default {
		components: {
			Link,
		},
		data() {
			return {
				items: [
					{
						label: "Dashboard",
						href: "/admin/dashboard",
					},
					{
						label: "Alertas",
						href: "/admin/alerts",
					},
					{
						label: "Órdenes de trabajo",
						href: "/admin/operation-orders",
					},
					{
						label: "Clientes",
						href: "/admin/customers",
					},
					{
						label: "Usuarios",
						href: "/admin/users",
						permission: "users",
					},
					// {
					// 	label: "Demo",
					// 	href: "/admin/demo",
					// },
				],
			};
		},
		methods: {
			...mapMutations(["toggleSidebar"]),
		},
	};
</script>
