<template>
	<div id="login" class="d-flex justify-content-center align-items-center">
		<div class="login-container bg-white rounded overflow-hidden">
			<div class="d-flex flex-column flex-md-row">
				<div class="image">
					<img src="@/assets/images/login-bg.jpg" alt="Logo" />
				</div>
				<div
					class="form d-flex flex-column align-items-start justify-content-center py-3 px-1 p-md-5"
				>
					<img
						class="mx-auto mb-3"
						src="@/assets/images/logotipo.png"
					/>
					<LoginForm />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import LoginForm from "@/components/login/LoginForm.vue";

	export default {
		components: {
			LoginForm,
		},
	};
</script>

<style lang="scss" scoped>
	#login {
		height: 100vh;

		.login-container {
			width: 800px;
			max-width: 90vw;

			.image {
				width: 40%;

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}

			.form {
				width: 60%;
			}
		}

		// Mobiles
		@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			background-image: url("@/assets/images/login-bg.jpg");
			.login-container {
				.image {
					display: none;
				}

				.form {
					width: 100%;
				}
			}
		}
	}
</style>
