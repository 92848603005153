import { createWebHistory, createRouter } from "vue-router";

import axios from "./axios";
import store from "./store";

// Layouts
import LoginLayout from "@/components/layouts/LoginLayout.vue";
import AdminLayout from "@/components/layouts/AdminLayout.vue";

// Admin
import Dashboard from "@/components/dashboard/Dashboard.vue";
import Users from "@/components/users/Users.vue";
import Customers from "@/components/customers/Customers.vue";
import OperationOrders from "@/components/operation-orders/OperationOrders.vue";
import Alerts from "@/components/alerts/Alerts.vue";
import Demo from "@/components/demo/Demos.vue";

const routes = [
	{
		path: "/login",
		component: LoginLayout,
		beforeEnter: checkLogin,
		name: "login",
		meta: {
			title: "Login",
		},
	},
	{
		path: "/admin",
		component: AdminLayout,
		beforeEnter: checkUserLogged,
		children: [
			{
				path: "dashboard",
				name: "Dashboard",
				component: Dashboard,
				meta: {
					title: "Dashboard",
				},
			},
			{
				path: "users",
				name: "Usuarios",
				component: Users,
				meta: {
					title: "Usuarios",
				},
			},
			{
				path: "customers",
				name: "Clientes",
				component: Customers,
				meta: {
					title: "Clientes",
				},
			},
			{
				path: "operation-orders",
				name: "Órdenes de trabajo",
				component: OperationOrders,
				meta: {
					title: "Órdenes de trabajo",
				},
			},
			{
				path: "alerts",
				name: "Alertas",
				component: Alerts,
				meta: {
					title: "Alertas y notificaciones",
				},
			},
			{
				path: "demo",
				name: "Demo",
				component: Demo,
				meta: {
					title: "Demo",
				},
			},
		],
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/admin/dashboard",
	},
];

function checkLogin(to, from, next) {
	if (localStorage.getItem("accessToken") != null) {
		next({ path: "/admin/dashboard" });
	} else {
		next();
	}

	return;
}

function checkUserLogged(to, from, next) {
	if (localStorage.getItem("accessToken") == null) {
		next({ path: "/login" });

		return;
	}

	axios.defaults.headers.common[
		"Authorization"
	] = `Bearer ${localStorage.getItem("accessToken")}`;

	axios
		.post("/check-user-logged")
		.then((response) => {
			store.commit("changeUserLogged", response.data.user);

			if (to.path === "/login") {
				next({ path: "/admin/dashboard" });
			} else {
				next();
			}
		})
		.catch((error) => {
			console.error(
				"Router JS ~ checkAdminRights",
				error.response,
				error.response?.data?.message
			);

			if (error.response.data.message === "CSRF token mismatch.") {
				location.reload();
			}

			if (
				error.response.data.message == "User not found" ||
				error.response.data.message == "Unauthorized" ||
				error.response.data.message == "Unauthenticated."
			) {
				window.localStorage.clear();
				next({ path: "/login" });
			}
		});

	return;
}

const router = new createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	// Get the page title from the route meta data that we have defined
	// See further down below for how we setup this data
	const title = to.meta.title;

	//Take the title from the parameters
	const titleFromParams = to.params.pageTitle;
	// If the route has a title, set it as the page title of the document/page
	if (title) {
		document.title = title;
	}
	// If we have a title from the params, extend the title with the title
	// from our params
	if (titleFromParams) {
		document.title = `${titleFromParams} - ${document.title}`;
	}
	// Continue resolving the route
	next();
});

export default router;
