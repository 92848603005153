<template>
	<Card class="no-custom-card">
		<template #content>
			<div class="d-flex align-items-center gap-2">
				<div class="emoji h3">👋🏻</div>
				<div>
					Bienvenid@ a la intranet de
					<span class="text-primary fw-bold">
						<a href="https://www.copyriver.com/" target="_blank" class="text-decoration-none"
							>COPYRIVER S.L.</a
						>
					</span>
				</div>
			</div>
		</template>
	</Card>
</template>

<script>
	import Card from "primevue/card";

	export default {
		components: {
			Card,
		},
	};
</script>

<style lang="scss" scoped>
	.emoji {
		transform: translateY(4px);
	}
</style>
