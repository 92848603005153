<template>
	<TableDefault
		ref="tableDefault"
		:route="route"
		:stateVariable="stateVariable"
		:list="$store.state[stateVariable].list"
		:total="$store.state[stateVariable].listTotal"
		:filters="filters"
		:delete="'operation-orders'"
		:showActionsColumn="false"
		:customTitle="tableTitle"
		:additionalParams="{
			selectedCustomerId: selectedCustomer?.id,
			fromAlerts,
			onGoing,
		}"
		:showAddButton="!fromAlerts"
		:showSelectMultipleColumn="false"
		:filtersId="onGoing ? 'ongoing-orders' : 'not-ongoing-orders'"
	>
		<template #columns>
			<Column header="Nº OT" field="number"></Column>
			<Column header="Descripción">
				<template #body="slotProps">
					<LongTextColumn :data="slotProps.data.description" />
				</template>
			</Column>
			<Column
				v-if="selectedCustomer == null"
				header="Cliente"
				field="customer.name"
			></Column>
			<Column header="Estado" field="status"></Column>
			<Column header="Asignada a">
				<template #body="slotProps">
					<NullableColumn :data="slotProps.data.user?.name" />
				</template>
			</Column>
			<Column header="Creada" field="created_at"></Column>

			<Column header="Acciones">
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2">
						<Button
							class="border-primary bg-primary"
							icon="pi pi-print"
							v-tooltip.top="'Imprimir'"
							@click="
								printOperationOrder({
									id: slotProps.data.id,
									mode: 'intranet',
								})
							"
						/>
						<Button
							class="border-primary bg-primary"
							icon="pi pi-file"
							v-tooltip.top="'Imprimir resguardo para el cliente'"
							@click="
								printOperationOrder({
									id: slotProps.data.id,
									mode: 'public',
								})
							"
						/>
						<Button
							class="border-primary bg-primary"
							icon="pi pi-pencil"
							@click="handleShowRegister(slotProps.data, 'edit')"
						/>
						<!-- <Button
							class="border-primary bg-primary"
							icon="pi pi-trash"
							@click="handleDeleteRegister(slotProps.data.id)"
						/> -->
					</div>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";
	import { mapActions, mapState } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
			selectedCustomer: {
				type: Object,
				required: false,
			},
			fromAlerts: {
				type: Boolean,
				required: false,
			},
			onGoing: {
				type: Boolean,
				required: false,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "number",
						value: null,
						type: "string",
						placeholder: "Nº OT",
					},
					{
						name: "description",
						value: null,
						type: "string",
						placeholder: "Descripción",
					},
					{
						name: "customer",
						value: null,
						type: "string",
						placeholder: "Cliente",
					},
					{
						name: "status",
						value: null,
						type: "dropdown",
						placeholder: "Estado",
						options: [
							{
								label: "No abierta",
								value: "No abierta",
							},
							{
								label: "Abierta",
								value: "Abierta",
							},
						],
					},
					{
						name: "user",
						value: null,
						type: "string",
						placeholder: "Asignada a",
					},
					{
						name: "created_at",
						value: null,
						type: "date",
						placeholder: "Creada",
					},
				],
			};
		},
		beforeMount() {
			if (this.selectedCustomer) {
				this.filters = this.filters.filter(
					(filter) => filter.name !== "customer"
				);
			}
		},
		computed: {
			...mapState(["operationOrderSaved"]),
			tableTitle() {
				if (!this.selectedCustomer && !this.fromAlerts) {
					return "Órdenes de trabajo";
				} else if (this.selectedCustomer) {
					return `Órdenes de trabajo de ${this.selectedCustomer.name}`;
				} else {
					if (this.onGoing) {
						return "OTs en curso";
					} else {
						return "OTs generadas y no leídas";
					}
				}
			},
		},
		methods: {
			...mapActions(["printOperationOrder"]),
			handleShowRegister(data, mode) {
				this.$refs.tableDefault.showRegister(data, mode);
			},
			handleDeleteRegister(id) {
				this.$refs.tableDefault.deleteRegister(id);
			},
			handleGetRegisters() {
				this.$refs.tableDefault.getList();
			},
		},
		watch: {
			operationOrderSaved() {
				if (this.operationOrderSaved) {
					this.handleGetRegisters();
				}
			},
		},
	};
</script>
