<template>
	<div>
		<OperationOrders :fromAlerts="true" :onGoing="false" />
		<OperationOrders :fromAlerts="true" :onGoing="true" class="mt-4" />
	</div>
</template>

<script>
	import OperationOrders from "@/components/operation-orders/OperationOrders.vue";

	export default {
		components: {
			OperationOrders,
		},
	};
</script>
